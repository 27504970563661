import React from 'react';
import cl from 'clsx';
import { iconsPaths } from 'src/constants';
import { Typography } from 'src/ui-kit';
import { nf2 } from 'src/utils';

import styles from './CoinRate.module.scss';

export type CoinRateProps = {
  id: string;
  currency: string;
  price: number;
  change: number;
  className?: string;
};

const CoinRate = ({ id, currency, price, change, className }: CoinRateProps) => {
  return (
    <div className={cl(styles.coinRate, className && className)}>
      {/* @ts-ignore */}
      <img src={iconsPaths[id]!} className={styles.icon} alt={`${id} icon`} />
      <div className={styles.value}>
        <Typography.Small className={cl(styles.symbol, styles.text)}>
          {currency.toLocaleUpperCase()}:
        </Typography.Small>
        &nbsp;
        <Typography.Small className={cl(styles.text, styles.price)}>
          $ {nf2.format(price)}
        </Typography.Small>
      </div>
      <div>
        <Typography.Small
          className={cl(
            'number',
            styles.text,
            styles.price,
            change > 0 && styles.up,
            change < 0 && styles.down
          )}
        >
          {nf2.format(change)}%
        </Typography.Small>
      </div>
    </div>
  );
};

export default CoinRate;
