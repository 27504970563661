import React, { useEffect, useRef, useState } from 'react';
import {
  ButtonPrimary,
  DownloadReport,
  Dropdown,
  EMPTY_VALUE,
  Legenda,
  ProViewSwitcher,
  Typography,
} from 'src/ui-kit';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { selectIsProView, switchIsProView } from 'src/store/slices/uiSlice';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  selectActiveView,
  selectActiveViewId,
  selectCustomViewBalance,
  selectCustomViewList,
  setCustomViewBalance,
  setCustomViewProBalance,
} from 'src/store/slices/customViewsSlice';
import {
  selectAccountBalance,
  selectAccountList,
  selectActiveAccount,
  selectActiveAccountId,
  selectIsFetchingBalances,
  setAccountBalance,
  setActiveAccountId,
  setIsFetchingBalances,
  setProViewBalance,
} from 'src/store/slices/accountSlice';
import { ReactComponent as UploadIcon } from 'src/assets/images/upload.svg';
import { StateService } from 'src/services/States.service';
import { logError } from 'src/utils';

import styles from './DashboardControls.module.scss';
import { selectIsMainView, setActiveViewId } from 'src/store/slices/activeView';
import { fetchAccountBalance, fetchCustomViewBalance } from 'src/thunks';
import { useNavigate } from 'react-router-dom';
import { MAIN_VIEW_ROUTE } from 'src/constants';

const DashboardControls = () => {
  const dispatch = useAppDispatch();

  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [isVisibleDownloadReport, setIsVisibleDownloadReport] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState<
    null | { value: string; label: string; isAccount: boolean }[]
  >(null);

  const isMainScreen = useAppSelector(selectIsMainView);
  const isProView = useAppSelector(selectIsProView);
  const accountList = useAppSelector(selectAccountList);
  const activeAccount = useAppSelector(selectActiveAccount);
  const activeAccountId = useAppSelector(selectActiveAccountId);
  const activeView = useAppSelector(selectActiveView);
  const activeViewId = useAppSelector(selectActiveViewId);
  const accountBalance = useAppSelector(selectAccountBalance);
  const customViewBalance = useAppSelector(selectCustomViewBalance);
  const isFetchingBalances = useAppSelector(selectIsFetchingBalances);
  const customViewsList = useAppSelector(selectCustomViewList);
  const navigate = useNavigate();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (accountList && accountList.length > 0) {
      setDropdownOptions(
        [...accountList, EMPTY_VALUE, ...customViewsList].reduce((acc, item: any) => {
          if (
            item === EMPTY_VALUE &&
            accountList.length > 0 &&
            customViewsList.length > 0
          ) {
            acc.push(item);
            return acc;
          } else if (item.accountId) {
            acc.push({
              value: `account-${item.accountId}`,
              label: item.accountName,
              isAccount: true,
            });
          } else {
            acc.push({
              value: `cview-${item.viewId}`,
              label: item.viewName,
              isAccount: false,
            });
          }

          return acc;
        }, [] as { value: string; label: string; isAccount: boolean }[])
      );
    }
  }, [accountList, customViewsList]);

  const handleNavigateToMainView = () => navigate(`/${MAIN_VIEW_ROUTE}`);
  const handleNavigateToCustomView = (viewId: number) => navigate(`/${viewId}`);

  // TODO! Refactor and move to middleware

  let balanceRequestInterval: React.MutableRefObject<null | NodeJS.Timeout> =
    useRef(null);

  const clearInterval = (interval: React.MutableRefObject<null | NodeJS.Timeout>) => {
    if (interval.current) {
      window.clearInterval(interval.current);
      interval.current = null;
    }
  };

  const requestBalances = (
    interval: React.MutableRefObject<null | NodeJS.Timeout>,
    trigger: 'account' | 'view'
  ) => {
    let calledFromIntervalCallback = false;

    const callback = async () => {
      if (!calledFromIntervalCallback) {
        dispatch(setIsFetchingBalances(true));
      }

      if (trigger === 'account') {
        try {
          if (!calledFromIntervalCallback) {
            if (isProView) {
              dispatch(setProViewBalance(null));
            } else {
              dispatch(setAccountBalance([]));
            }
          }

          await fetchAccountBalance(
            dispatch,
            activeAccountId,
            isProView,
            calledFromIntervalCallback
          );
        } catch (err) {
          logError(err);
        }
      } else {
        try {
          if (!calledFromIntervalCallback) {
            dispatch(setCustomViewBalance([]));
            dispatch(setCustomViewProBalance([]));
          }

          await fetchCustomViewBalance(
            dispatch,
            activeViewId,
            isProView,
            calledFromIntervalCallback
          );
        } catch (err) {
          logError(err);
        }
      }

      calledFromIntervalCallback = true;
    };

    callback();

    interval.current = setInterval(
      callback,
      Number(process.env.REACT_APP_BALANCE_REFRESH_RATE)
    );
  };

  useEffect(() => {
    return () => {
      clearInterval(balanceRequestInterval);
    };
  }, []);

  useEffect(() => {
    if (activeViewId !== 0) {
      dispatch(setActiveAccountId(null));

      clearInterval(balanceRequestInterval);

      requestBalances(balanceRequestInterval, 'view');
    } else {
      if (accountList.length) {
        const persistedActiveAccountId = localStorage.getItem('activeAccountId');

        if (persistedActiveAccountId !== null) {
          const persistedActiveAccount = accountList.find((account) => {
            return account.accountId === Number(persistedActiveAccountId);
          });

          if (persistedActiveAccount) {
            dispatch(setActiveAccountId(persistedActiveAccount.accountId));
          }
        } else {
          dispatch(setActiveAccountId(accountList[0].accountId));
        }
      } else {
        dispatch(setIsFetchingBalances(false));
      }
    }
  }, [activeViewId]);

  useEffect(() => {
    if (activeAccountId !== null) {
      clearInterval(balanceRequestInterval);

      localStorage.setItem('activeAccountId', `${activeAccountId}`);

      requestBalances(balanceRequestInterval, 'account');
    }
  }, [activeAccountId]);

  // END TODO!

  const fetchPDF = async (checkedReports: [boolean, boolean]) => {
    setIsFetchingReport(true);
    try {
      if (checkedReports[0]) {
        const response = isMainScreen
          ? await StateService.reportAccount(activeAccountId!)
          : await StateService.reportView(activeViewId);
        if (response) {
          const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadURL;
          link.setAttribute('download', response.headers['content-disposition']!);
          document.body.appendChild(link);
          link.click();
        }
      }
      if (checkedReports[1]) {
        const response = isMainScreen
          ? await StateService.riskReportAccount(activeAccountId!)
          : await StateService.riskReportView(activeViewId);
        if (response) {
          const downloadURL = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadURL;
          link.setAttribute('download', response.headers['content-disposition']!);
          document.body.appendChild(link);
          link.click();
        }
      }
    } catch (err) {
      logError(err);
    } finally {
      setIsVisibleDownloadReport(false);
      setIsFetchingReport(false);
    }
  };

  return (
    <div className={styles.topWrapper}>
      <div className={styles.topLeftWrapper}>
        {dropdownOptions && (
          <>
            <Typography.H2 className={styles.title}>Portfolios</Typography.H2>
            <Dropdown
              defaultValue={
                activeViewId === 0
                  ? `account-${activeAccountId}`
                  : `cview-${activeViewId}`
              }
              options={dropdownOptions}
              // @ts-ignore
              label={
                activeViewId === 0
                  ? activeAccount?.accountName || accountList[0].accountName
                  : activeView?.viewName
              }
              onChange={(option: any) => {
                const id = Number(option.value.split('-')[1]);
                if (option.isAccount) {
                  dispatch(setActiveAccountId(id));
                  dispatch(setActiveViewId('main-view'));
                  handleNavigateToMainView();
                } else {
                  dispatch(setActiveViewId(id));
                  handleNavigateToCustomView(id);
                }
              }}
            />
            <div className={styles.legendaWrapper}>
              <Legenda variant="exchanges" />
              <Legenda variant="portfolio" />
            </div>
          </>
        )}
      </div>
      <div className={styles.topRightWrapper}>
        <ButtonPrimary
          icon={<UploadIcon />}
          text="Statements"
          isDisabled={
            isFetchingBalances ||
            (isMainScreen && accountList.length === 0) ||
            (isMainScreen && accountBalance?.exchangeBalances?.length === 0) ||
            (!isMainScreen && customViewBalance?.accountBalances?.length === 0)
          }
          className={styles.statements}
          onClick={() => setIsVisibleDownloadReport(true)}
        />
        <DownloadReport
          isVisible={isVisibleDownloadReport}
          onClose={() => setIsVisibleDownloadReport(false)}
          onSubmit={fetchPDF}
          isBlocked={isFetchingReport}
          className={styles.report}
        />
      </div>
    </div>
  );
};

export default DashboardControls;
