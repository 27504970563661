import { useEffect, useState } from 'react';
import {
  ButtonPrimary,
  ButtonSecondary,
  Dropdown,
  DropdownOption,
  EMPTY_VALUE,
  MessageBox,
  Modal,
  ModalSidebar,
  RadioButton,
  TextInput,
  Typography,
} from 'src/ui-kit';
import cl from 'clsx';
import ct from 'countries-and-timezones';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';
import {
  selectUserProfileModalIsOpen,
  setIsDarkTheme,
  switchUserProfileModalIsOpen,
} from 'src/store/slices/uiSlice';
import styles from './ModalUserProfile.module.scss';
import { AccountInfo } from './components';
import { UserService } from 'src/services/User.service';
import { selectUser, setUser } from 'src/store/slices/userSlice';
import { sortBy } from 'lodash';

import { ApiKeysMenuSection } from 'src/components';

const ModalUserProfile = () => {
  const [timezoneDropdownOptions, setTimezoneDropdownOptions] = useState<
    DropdownOption[]
  >([]);

  const [activeTimezone, setActiveTimezone] = useState<DropdownOption | null>(null);
  const [colorTheme, setColorTheme] = useState<0 | 1 | 2>(2);
  const [sections, setSections] = useState<0 | 1 | 2 | 3 | 4 | 5>(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [passwordReset, setPasswordReset] = useState<null | {
    type: 'error' | 'success';
    text: string;
  }>(null);
  const [userInfo, setUserInfo] = useState<null | {
    type: 'error' | 'success';
    text: string;
  }>(null);
  const [settings, setSettings] = useState<null | {
    type: 'error' | 'success';
    text: string;
  }>(null);

  const user = useAppSelector(selectUser);
  const isOpen = useAppSelector(selectUserProfileModalIsOpen);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(switchUserProfileModalIsOpen());
  };

  const [isOpenAvatar, setIsOpenAvatar] = useState(false);

  useEffect(() => {
    const timezones = Object.values(ct.getAllTimezones());
    setTimezoneDropdownOptions(
      sortBy(timezones, ['utcOffset']).reduce(
        (accumulator: DropdownOption[], currentValue) => {
          if (
            !currentValue.name.includes('/') ||
            currentValue.name.includes('+') ||
            currentValue.name.includes('-')
          ) {
            return accumulator;
          }

          let label = '';
          let time: string | number = Number(currentValue.utcOffsetStr.split(':')[0]);

          if (time >= 0) {
            time = `+${time}`;
          }

          label += `(UTC${time}) ${currentValue.name.split('/')[1].replace('_', ' ')}`;
          accumulator.push({
            value: currentValue.name,
            label,
          });

          return accumulator;
        },
        [
          {
            value: 'UTC',
            label: 'UTC',
          },
          {
            value: 'Tokyo',
            label: 'Tokyo',
          },
          {
            value: 'Moscow',
            label: 'Moscow',
          },
          {
            value: 'Berlin',
            label: 'EU ',
          },
          {
            value: 'London',
            label: 'London ',
          },
          {
            value: 'New York',
            label: 'New York',
          },
          EMPTY_VALUE,
        ]
      )
    );

    setActiveTimezone(timezoneDropdownOptions[0]);
  }, []);

  useEffect(() => {
    setColorTheme(user.userTheme);
    if (user.userTimezone) {
      const [timeZoneValue, timeZoneLabel] = user.userTimezone.split('|');
      setActiveTimezone({
        value: timeZoneValue,
        label: timeZoneLabel,
      });
    }
  }, [user]);

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setCompanyName(user.companyName);
    const [timeZoneValue, timeZoneLabel] = user.userTimezone.split('|');
    setActiveTimezone({
      value: timeZoneValue,
      label: timeZoneLabel,
    });
  }, [user]);

  useEffect(() => {
    setPasswordReset(null);
    setUserInfo(null);
    setSettings(null);
  }, [sections]);

  const resetSettings = () => {
    setColorTheme(user.userTheme);
    if (user.userTimezone) {
      const [timeZoneValue, timeZoneLabel] = user.userTimezone.split('|');
      setActiveTimezone({
        value: timeZoneValue,
        label: timeZoneLabel,
      });
    }
  };

  return (
    <Modal
      title="User profile"
      isOpen={isOpen}
      onClose={handleClose}
      className={styles.modalUserProfile}
    >
      <AccountInfo isOpenAvatar={isOpenAvatar} setIsOpenAvatar={setIsOpenAvatar} />
      <div className={cl(styles.wrapper, isOpenAvatar && styles.blur)}>
        <ModalSidebar
          isInvertedBg
          sections={[
            {
              name: 'User Info',
              isActive: sections === 0,
              onClick: () => setSections(0),
            },
            {
              name: 'Settings',
              isActive: sections === 1,
              onClick: () => setSections(1),
            },
            { name: 'Security', isActive: sections === 2, onClick: () => setSections(2) },

            {
              name: 'Subscription',
              isActive: sections === 4,
              isDisabled: true,
              onClick: () => setSections(4),
            },
          ]}
        />
        {sections === 0 && (
          <div className={styles.rightSection}>
            <div>
              <div className={styles.tabsWrapper}>
                <button className={cl(styles.tab, styles.tabActive)}>Main info</button>
              </div>

              <div className={styles.tabContent}>
                <div className={styles.inputWrapper}>
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    First name
                  </Typography.P1>
                  <TextInput
                    value={firstName}
                    placeholder="Enter your first name"
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                    className={styles.input}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    Last name
                  </Typography.P1>
                  <TextInput
                    value={lastName}
                    placeholder="Enter your last name"
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                    className={styles.input}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    Company name
                  </Typography.P1>
                  <TextInput
                    value={companyName}
                    placeholder="Enter your company name"
                    onChange={(event) => {
                      setCompanyName(event.target.value);
                    }}
                    className={styles.input}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    Email (username)
                  </Typography.P1>
                  <Typography.P1
                    className={cl(styles.inputLabel, styles.userEmail)}
                    weight={500}
                  >
                    {user.email}
                  </Typography.P1>
                </div>
              </div>
            </div>
            {userInfo && userInfo.type === 'success' && (
              <MessageBox
                type="success"
                text="Changes saved"
                className={styles.messageBox}
              />
            )}
            {userInfo && userInfo.type === 'error' && (
              <MessageBox
                type="error"
                text="Internal server error"
                className={styles.messageBox}
              />
            )}
            <div className={styles.profileButtons}>
              <ButtonSecondary
                text="Reset"
                onClick={() => {
                  setFirstName(user.firstName);
                  setLastName(user.lastName);
                  setCompanyName(user.companyName);
                }}
                className={styles.actionButton}
              />
              <ButtonPrimary
                isFullWidth
                text="Save"
                onClick={async () => {
                  try {
                    await UserService.userUpdate({
                      firstName: firstName,
                      lastName: lastName,
                      companyName: companyName,
                      userPicture: user.userPicture,
                      userTheme: user.userTheme,
                      userTimezone: user.userTimezone,
                    });

                    dispatch(
                      setUser({
                        firstName: firstName,
                        lastName: lastName,
                        email: user.email,
                        companyName: companyName,
                        userPicture: user.userPicture,
                        userTheme: user.userTheme,
                        userTimezone: user.userTimezone,
                      })
                    );
                    setUserInfo({
                      type: 'success',
                      text: 'Your password successfully updated.',
                    });
                  } catch {
                    setUserInfo({
                      type: 'error',
                      text: 'Your password successfully updated.',
                    });
                  }
                }}
                className={styles.actionButton}
              />
            </div>
          </div>
        )}
        {sections === 1 && (
          <div className={styles.rightSection}>
            <div>
              <div className={styles.tabsWrapper}>
                <button className={cl(styles.tab, styles.tabActive)}>Settings</button>
              </div>
              <div className={styles.tabContent}>
                <div className={styles.timezone}>
                  <Typography.P1 className={styles.timezoneHeading}>
                    Time & geo zone
                  </Typography.P1>
                  <Typography.Additional className={styles.timezoneDescription}>
                    Choose your time zone which will be showed at home page
                  </Typography.Additional>
                  <Dropdown
                    label={activeTimezone ? activeTimezone.label : ''}
                    options={timezoneDropdownOptions}
                    onChange={(option) => {
                      setActiveTimezone(option);
                    }}
                    className={styles.timezoneDropdown}
                  />
                  <Typography.P1 className={styles.timezoneHeading}>
                    Color theme
                  </Typography.P1>
                  <Typography.Additional className={styles.timezoneDescription}>
                    Auto theme adapts to your OS theme
                  </Typography.Additional>
                  <div className={styles.radioButtons}>
                    <RadioButton
                      label="Light"
                      isActive={colorTheme === 0}
                      onChange={() => {
                        setColorTheme(0);
                      }}
                    />
                    <RadioButton
                      label="Dark"
                      isActive={colorTheme === 1}
                      onChange={() => {
                        setColorTheme(1);
                      }}
                    />
                    <RadioButton
                      label="Auto"
                      isActive={colorTheme === 2}
                      onChange={() => {
                        setColorTheme(2);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {settings && settings.type === 'success' && (
              <MessageBox
                type="success"
                text="Changes saved"
                className={styles.messageBox}
              />
            )}
            {settings && settings.type === 'error' && (
              <MessageBox
                type="error"
                text="Internal server error"
                className={styles.messageBox}
              />
            )}
            <div className={styles.profileButtons}>
              <ButtonSecondary
                text="Reset"
                onClick={resetSettings}
                className={styles.actionButton}
              />
              <ButtonPrimary
                isFullWidth
                text="Save"
                onClick={async () => {
                  try {
                    await UserService.userUpdate({
                      firstName: user.firstName,
                      lastName: user.lastName,
                      companyName: user.companyName,
                      userPicture: user.userPicture,
                      userTheme: colorTheme,
                      userTimezone: activeTimezone
                        ? `${activeTimezone.value}|${activeTimezone.label}`
                        : '',
                    });

                    dispatch(
                      setUser({
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        companyName: user.companyName,
                        userPicture: user.userPicture,
                        userTheme: colorTheme,
                        userTimezone: activeTimezone
                          ? `${activeTimezone.value}|${activeTimezone.label}`
                          : '',
                      })
                    );

                    if (colorTheme === 0) {
                      dispatch(setIsDarkTheme(false));
                    } else if (colorTheme === 1) {
                      dispatch(setIsDarkTheme(true));
                    } else {
                      localStorage.removeItem('is_dark_theme');

                      if (
                        window.matchMedia &&
                        window.matchMedia('(prefers-color-scheme: dark)').matches
                      ) {
                        dispatch(setIsDarkTheme(true));
                      } else {
                        dispatch(setIsDarkTheme(false));
                      }
                    }
                    setSettings({
                      type: 'success',
                      text: 'Your password successfully updated.',
                    });
                  } catch {
                    setSettings({
                      type: 'error',
                      text: 'Your password successfully updated.',
                    });
                  }
                }}
                className={styles.actionButton}
              />
            </div>
          </div>
        )}
        {sections === 2 && (
          <div className={styles.rightSection}>
            <div>
              <div className={styles.tabsWrapper}>
                <button className={cl(styles.tab, styles.tabActive)}>
                  Password management
                </button>
              </div>

              <div className={styles.tabContent}>
                <div className={styles.inputWrapper}>
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    Current password
                  </Typography.P1>
                  <TextInput
                    value={oldPassword}
                    isPassword
                    placeholder="Enter your current password"
                    onChange={(event: any) => {
                      setOldPassword(event.target.value);
                      setPasswordReset(null);
                    }}
                    className={styles.input}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    New password
                  </Typography.P1>
                  <TextInput
                    value={newPassword}
                    isPassword
                    placeholder="Enter your new password"
                    onChange={(event: any) => {
                      setNewPassword(event.target.value);
                      setPasswordReset(null);
                    }}
                    className={styles.input}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <Typography.P1 className={styles.inputLabel} weight={500}>
                    Password confirmation
                  </Typography.P1>
                  <TextInput
                    value={newPasswordConfirmation}
                    isPassword
                    placeholder="Confirm new password"
                    onChange={(event: any) => {
                      setNewPasswordConfirmation(event.target.value);
                      setPasswordReset(null);
                    }}
                    className={styles.input}
                  />
                </div>
                {passwordReset && passwordReset.type === 'success' && (
                  <MessageBox
                    type="success"
                    text="Your password succesfully updated"
                    className={styles.messageBox}
                  />
                )}
                {passwordReset && passwordReset.type === 'error' && (
                  <MessageBox
                    type="error"
                    text={passwordReset.text}
                    className={styles.messageBox}
                  />
                )}
              </div>
            </div>
            <div className={styles.profileButtons}>
              <ButtonSecondary
                text="Reset"
                onClick={() => {
                  setOldPassword('');
                  setNewPassword('');
                  setNewPasswordConfirmation('');
                }}
                className={styles.actionButton}
              />
              <ButtonPrimary
                isFullWidth
                text="Save"
                onClick={async () => {
                  if (newPassword !== newPasswordConfirmation) {
                    setPasswordReset({
                      type: 'error',
                      text: "Passwords don't match",
                    });
                  } else {
                    try {
                      await UserService.passwordChange({ oldPassword, newPassword });
                      setPasswordReset({
                        type: 'success',
                        text: 'Your password successfully updated.',
                      });
                      setOldPassword('');
                      setNewPassword('');
                      setNewPasswordConfirmation('');
                    } catch {
                      setPasswordReset({
                        type: 'error',
                        text: 'Authentication error',
                      });
                    }
                  }
                }}
                className={styles.actionButton}
              />
            </div>
          </div>
        )}
        {sections === 3 && (
          <div className={styles.rightSection}>
            <div>
              <div className={styles.tabsWrapper}>
                <button className={cl(styles.tab, styles.tabActive)}>Deactivation</button>
              </div>
              <div className={styles.tabContent}>
                <Typography.P1>You can deactivate your environment here.</Typography.P1>
                <br />
                <Typography.P1>
                  We save your data for 1 month and after that it will be deleted. You can
                  restore the environment during 1 month after deactivation.
                </Typography.P1>
                <br />
                <Typography.P1>
                  If you want to restore environment please click ‘restore account’ button
                  at Log In screen and follow the instruction.
                </Typography.P1>
              </div>
            </div>
            <div className={styles.profileButtons}>
              <ButtonPrimary
                isFullWidth
                text="Deactivate"
                onClick={() => {}}
                className={styles.actionButton}
              />
            </div>
          </div>
        )}
        {sections === 5 && <ApiKeysMenuSection />}
      </div>
    </Modal>
  );
};

export default ModalUserProfile;
