import cl from 'clsx';
import { Avatar, ButtonHeader, CoinRate, ThemeSwitcher, Typography } from 'src/ui-kit';
import { Price } from 'src/types/price';
import { ReactComponent as SettingsIcon } from 'src/assets/images/settings_2.svg';
import { ReactComponent as NotepadIcon } from 'src/assets/images/notepad.svg';
import { ReactComponent as Logo } from 'src/assets/images/oreome-logo.svg';
import { ReactComponent as MyEnvironmentIcon } from 'src/assets/images/my-environment.svg';
import { ReactComponent as UserIcon } from 'src/assets/images/user-icon.svg';
import { ReactComponent as SignOutIcon } from 'src/assets/images/sign-out.svg';

import { ReactComponent as DocsIcon } from 'src/assets/images/docs.svg';
import { logout } from 'src/store/slices/userSlice';
import styles from './HeaderPlate.module.scss';
import { useDispatch } from 'react-redux';
import { DOCS_LINK } from 'src/constants';
import { U } from 'chart.js/dist/chunks/helpers.core';

type HeaderPlateProps = {
  exchangeRates: Price[];
  className?: string;
  firstName: string;
  lastName: string;
  userPicture: 0 | 1 | 2 | 3 | 4;
  isDarkTheme: boolean;
  switchTheme: () => void;
  openModal: () => void;
  openUserProfileModal: () => void;
  openNotepadModal: () => void;
};

const HeaderPlate = ({
  exchangeRates = [],
  firstName,
  lastName,
  userPicture,
  isDarkTheme,
  className,
  openModal,
  switchTheme,
  openUserProfileModal,
  openNotepadModal,
}: HeaderPlateProps) => {
  const dispatch = useDispatch();

  return (
    <header className={cl(styles.headerPlate, className && className)}>
      <div className={styles.leftSide}>
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} />
        </div>
        <div className={styles.coinRates}>
          {exchangeRates.map(({ currency, usdPrice, changePercent }) => {
            if (currency.ticker === ('USDT' || 'USDC')) {
              return null;
            }
            return (
              <CoinRate
                currency={currency.ticker}
                id={currency.id}
                price={usdPrice}
                change={changePercent}
                key={currency.id}
              />
            );
          })}
        </div>
      </div>

      <div className={styles.btns}>
        <ThemeSwitcher
          isDarkTheme={isDarkTheme}
          onClick={switchTheme}
          className={styles.themeSwitcher}
        />
        {/* <button
          className={cl(styles.signOut, styles.leftBtns, styles.notepadButton)}
          onClick={openNotepadModal}
        >
          <Typography.Small className={styles.signOutText}>Notepad</Typography.Small>
          <NotepadIcon />
        </button> */}
        <ButtonHeader
          icon={<NotepadIcon className={styles.notepadButtonIcon} />}
          onClick={openNotepadModal}
          className={styles.notepadButton}
        />
        <button className={cl(styles.signOut, styles.leftBtns)} onClick={openModal}>
          <Typography.Small className={styles.signOutText}>
            My Environment
          </Typography.Small>
          <MyEnvironmentIcon />
        </button>
        <button
          className={cl(styles.signOut, styles.leftBtns, styles.profile)}
          onClick={openUserProfileModal}
        >
          <Typography.Small className={styles.signOutText}>
            {firstName} {lastName}
          </Typography.Small>
          <UserIcon />
        </button>
        <button
          className={styles.signOut}
          onClick={() => {
            dispatch(logout(false));
          }}
        >
          <Typography.Small className={styles.signOutText}>Sign Out</Typography.Small>
          <SignOutIcon className={styles.signOutIcon} />
        </button>
      </div>
    </header>
  );
};

export default HeaderPlate;
