import { useEffect, useState } from 'react';

import cl from 'clsx';
import { ButtonPrimary, ButtonSecondary, MessageBox, Typography } from 'src/ui-kit';

import { ReactComponent as SuccessIcon } from 'src/assets/images/successIcon.svg';
import { ReactComponent as DeleteTableRowIcon } from 'src/assets/images/deleteTableRow.svg';

import styles from './ApiKeysMenuSection.module.scss';
import { logError } from 'src/utils';
import { UserService } from 'src/services/User.service';
import { selectIsApiClient } from 'src/store/slices/userSlice';
import { useAppSelector } from 'src/hooks/useAppSelector';

enum ApiScreen {
  RequestAccess = 'RequestAccess',
  AccessRequested = 'AccessRequested',
  KeysList = 'KeysList',
}

const ApiKeysMenuSection = () => {
  const [apiScreen, setApiScreen] = useState<ApiScreen>(ApiScreen.RequestAccess);
  const [error, setError] = useState<null | string>(null);
  const [isCopied, setIsCopied] = useState(false);
  const [keysList, setKeysList] = useState<Record<string, string>>({});
  const [keyForDelete, setKeyForDelete] = useState<null | string>(null);
  const isApiClient = useAppSelector(selectIsApiClient);

  useEffect(() => {
    if (isApiClient) {
      setApiScreen(ApiScreen.KeysList);
      getApiKeys();
    }
  }, [isApiClient]);

  const copyKey = (event: any) => {
    navigator.clipboard.writeText(event.target.textContent);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const getApiKeys = async () => {
    try {
      const response: any = await UserService.apiKeys();
      setKeysList(response.data);
    } catch (error) {
      logError(error);
    }
  };

  const handleRequestAccessClick = async () => {
    try {
      await UserService.requestAccess();
      setApiScreen(ApiScreen.AccessRequested);
    } catch (error: any) {
      logError(error);
      setError(error.response.data.message);
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  };

  const handleGenerateKeyClick = async () => {
    try {
      const response: any = await UserService.generateKey();
      setKeysList({
        ...keysList,
        ...response.data,
      });
    } catch (error: any) {
      logError(error);
      setError(error.response.data.message);
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  };

  return (
    <>
      <div className={styles.rightSection}>
        {apiScreen === ApiScreen.RequestAccess && (
          <>
            <div>
              <div className={cl(styles.tabsWrapper, styles.tabsWrapperApi)}>
                <button className={cl(styles.tab, styles.tabActive)}>
                  OREOME api keys
                </button>
              </div>
              <Typography.P1>
                OREOME API provides documentation and code examples for using Risk
                management and Data analysis features in your services
              </Typography.P1>
              <br />
              <Typography.P1 color="secondary">
                Seems like you haven’t got access to OREOME API.
                <br />
                <br />
                Please click the button below if you need the access.
                <br />
                <br />
                Your application will be send automatically. Our team will get in touch
                with you as soon as possible.
              </Typography.P1>
              <ButtonPrimary
                text="Request access"
                onClick={handleRequestAccessClick}
                className={styles.requestAccess}
              />
              {error && (
                <MessageBox
                  type="error"
                  text={error}
                  // className={styles.messageBox}
                />
              )}
            </div>
            <div>
              <Typography.P1 weight={500} className={styles.apiDocumentationHeading}>
                Documentation
              </Typography.P1>
              <Typography.P1 className={styles.apiDocumentationDescription}>
                If you need additional help check our API documentation on{' '}
                <a
                  href={process.env.REACT_APP_API_DOCUMENTATION_URL}
                  className={styles.apiLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  OREOME_Dev
                </a>
              </Typography.P1>
            </div>
          </>
        )}
        {apiScreen === ApiScreen.AccessRequested && (
          <>
            <div>
              <div className={cl(styles.tabsWrapper, styles.tabsWrapperApi)}>
                <button className={cl(styles.tab, styles.tabActive)}>
                  OREOME api keys
                </button>
              </div>
              <div className={styles.sentRequest}>
                <SuccessIcon />
                <Typography.P1>Request has been sent</Typography.P1>
              </div>
              <Typography.P1>
                If you faced some troubles, please contact us on{' '}
                <a
                  href={process.env.REACT_APP_WEBSITE_CONTACTS_URL}
                  className={styles.apiLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  contact us.
                </a>
              </Typography.P1>
              {/* <ButtonPrimary
                text="Request access"
                onClick={handleRequestAccessClick}
                isDisabled
                className={styles.requestAccess}
              /> */}
              {/* <Typography.P1 color="secondary">
                You can send another request after 23:59:40
              </Typography.P1> */}
            </div>
            <div>
              <Typography.P1 weight={500} className={styles.apiDocumentationHeading}>
                Documentation
              </Typography.P1>
              <Typography.P1 className={styles.apiDocumentationDescription}>
                If you need additional help check our API documentation on{' '}
                <a
                  href={process.env.REACT_APP_API_DOCUMENTATION_URL}
                  className={styles.apiLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  OREOME_Dev
                </a>
              </Typography.P1>
            </div>
          </>
        )}
        {apiScreen === ApiScreen.KeysList && (
          <>
            <div>
              <div className={cl(styles.tabsWrapper, styles.tabsWrapperApi)}>
                <button className={cl(styles.tab, styles.tabActive)}>
                  OREOME api keys
                </button>
              </div>
              <div>
                <div>
                  <div className={styles.generateKey}>
                    <Typography.P1 className={styles.apiDocumentationDescription}>
                      You can generate up to 3 API-key pairs.
                    </Typography.P1>
                    <ButtonPrimary
                      text="Generate API Key"
                      onClick={handleGenerateKeyClick}
                      className={styles.generateKeyBtn}
                    />
                  </div>

                  <table className={styles.apiTable}>
                    <thead>
                      <tr>
                        <th>ACCESS-KEY</th>
                        <th>SECRET-KEY</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(keysList).map((key) => (
                        <tr key={key}>
                          <td>
                            <div className={styles.copyable} onClick={copyKey}>
                              {key}
                            </div>
                          </td>
                          <td>
                            <div className={styles.copyable} onClick={copyKey}>
                              {keysList[key]}
                            </div>
                          </td>
                          <td>
                            <button
                              className={styles.deleteTableRow}
                              onClick={() => {
                                setKeyForDelete(key);
                              }}
                            >
                              <DeleteTableRowIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isCopied && (
                    <MessageBox
                      type="success"
                      text="Key was copied"
                      // className={styles.messageBox}
                    />
                  )}
                  {error && (
                    <MessageBox
                      type="error"
                      text={error}
                      // className={styles.messageBox}
                    />
                  )}
                </div>
                <div className={styles.apiDocumentationWrapper}>
                  <Typography.P1 weight={500} className={styles.apiDocumentationHeading}>
                    Documentation
                  </Typography.P1>
                  <Typography.P1 className={styles.apiDocumentationDescription}>
                    If you need additional help check our API documentation on{' '}
                    <a
                      href={process.env.REACT_APP_API_DOCUMENTATION_URL}
                      className={styles.apiLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      OREOME_Dev
                    </a>
                  </Typography.P1>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {keyForDelete && (
        <div className={styles.deleteConfirmation}>
          <div className={styles.deleteContent}>
            <Typography.H3 weight={600} className={styles.deleteTitle}>
              Delete confirmation
            </Typography.H3>
            <div className={styles.deleteTextAndButtons}>
              <Typography.P1 className={styles.deleteText}>
                Are you sure you want to delete this key?
                {/* <br />
                You can generate a new one anytime. */}
              </Typography.P1>
              <div className={styles.buttonsWrapper}>
                <ButtonSecondary
                  text="Yes"
                  type="small"
                  onClick={async () => {
                    try {
                      await UserService.deleteApiKey(keyForDelete);
                      setKeyForDelete(null);
                      getApiKeys();
                    } catch (error) {
                      logError(error);
                    }
                  }}
                  className={styles.deleteBtnYes}
                />
                <ButtonPrimary
                  text="No"
                  size="small"
                  onClick={() => {
                    setKeyForDelete(null);
                  }}
                  className={styles.deleteBtnNo}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApiKeysMenuSection;
