import { Typography } from 'src/ui-kit';

import styles from './NewCustomInstrumentScreenHeader.module.scss';

type NewCustomInstrumentScreenHeaderProps = {};

const NewCustomInstrumentScreenHeader = ({}: NewCustomInstrumentScreenHeaderProps) => {
  return (
    <div className={styles.newAccountScreenHeader}>
      <div className={styles.setupWrapper}>
        <Typography.H1 className={styles.setup}>New Custom Position</Typography.H1>
      </div>
    </div>
  );
};

export default NewCustomInstrumentScreenHeader;
