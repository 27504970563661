import { useState } from 'react';
import {
  ButtonPrimary,
  ButtonSecondary,
  Card,
  Kebab,
  Switch,
  TextInput,
  TextInputSize,
  Typography,
} from 'src/ui-kit';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { ReactComponent as CustomViewIcon } from 'src/assets/images/customViewTitle.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/images/custom-view-open.svg';

import styles from './CustomViewsScreenItem.module.scss';
import { CustomViewService } from 'src/services/CustomView.service';
import { setCustomViewList } from 'src/store/slices/customViewsSlice';
import { logError } from 'src/utils';
import { CustomView } from 'src/types/customView';
import { ModalScreen } from '../../ModalMyEnvironment';
import { setMyEnvironmentModalScreen } from 'src/store/slices/uiSlice';
import { fetchAllCustomViews } from 'src/thunks';
import { ReactComponent as TickIcon } from 'src/assets/images/buttonTick.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/buttonCross.svg';
import { ReactComponent as SpinnerIcon } from 'src/assets/images/renameSpinner.svg';

type CustomViewsScreenItemProps = {
  viewId: number;
  viewName: string;
  portfolioIdWeightMap: Record<string, number>;
  isAttach: boolean;
  index: number;
  setCustomViewEdit: React.Dispatch<any>;
  onDelete: (account: any) => void;
};

const CustomViewsScreenItem = ({
  viewId,
  viewName,
  portfolioIdWeightMap,
  isAttach,
  index,
  setCustomViewEdit,
  onDelete,
}: CustomViewsScreenItemProps) => {
  const dispatch = useAppDispatch();

  const [inputText, setInputText] = useState<string>(viewName);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isSubmittingRename, setIsSubmittingRename] = useState<boolean>(false);

  const handleRenameBtnClick = () => {
    setIsEditMode(true);
  };

  const handleRename = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleBlur = async () => {
    setIsEditMode(false);
  };

  const handleChange = async () => {
    try {
      await CustomViewService.updateView(viewId, {
        portfolioIdWeightMap,
        isAttach: !isAttach,
        viewName,
      });

      const customViews: { data: CustomView[] } = await CustomViewService.allViews();

      dispatch(setCustomViewList(customViews.data));
    } catch (error) {
      logError(error);
    }
  };

  const handleRenameSubmit = async () => {
    setIsSubmittingRename(true);

    await CustomViewService.renameView(viewId, {
      viewName: inputText,
      isAttach: true,
      portfolioIdWeightMap,
    });

    await fetchAllCustomViews(dispatch);

    setIsEditMode(false);
    setIsSubmittingRename(false);
  };

  return (
    <Card className={styles.card}>
      <div className={styles.cardNameLine}>
        {!isEditMode && (
          <div className={styles.customViewName}>
            <CustomViewIcon className={styles.customViewIcon} />
            <Typography.P1 className={styles.cardName}>{viewName}</Typography.P1>
          </div>
        )}
        {isEditMode && (
          <div className={styles.renameWrapper}>
            <TextInput
              value={inputText}
              size={TextInputSize.Small}
              autoFocus
              onChange={handleRename}
              className={styles.input}
            />
            <div className={styles.renameBtnWrapper}>
              {isSubmittingRename ? (
                <SpinnerIcon className={styles.spinner} />
              ) : (
                <>
                  <ButtonSecondary
                    text=""
                    onClick={handleBlur}
                    className={styles.renameBtn}
                  >
                    <CrossIcon className={styles.crossIcon} />
                  </ButtonSecondary>
                  <ButtonPrimary
                    text=""
                    size="small"
                    icon={<TickIcon />}
                    onClick={handleRenameSubmit}
                    className={styles.renameBtn}
                  />
                </>
              )}
            </div>
          </div>
        )}

        <Kebab
          items={[
            { title: 'Rename', onClick: handleRenameBtnClick },
            {
              title: 'Delete',
              onClick: () => {
                onDelete({ id: viewId, accountName: viewName });
              },
            },
          ]}
          menuPosition={(index + 1) % 3 === 0 ? 'right' : 'left'}
        />
      </div>
      <div>
        <div className={styles.bottomLine}>
          <div>
            <button
              className={styles.manage}
              onClick={() => {
                setCustomViewEdit({
                  viewId,
                  viewName,
                  isAttach,
                });
                dispatch(setMyEnvironmentModalScreen(ModalScreen.CustomViewEdit));
              }}
            >
              <Typography.Small>Manage</Typography.Small>
              <ArrowIcon />
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CustomViewsScreenItem;
